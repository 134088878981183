import axios from 'axios';
import { TokenService } from './storageService';
import { APP_CONFIG } from '../utils/constants'

const getAuthToken = () => TokenService.getToken();

const httpClient = axios.create({
    baseURL: APP_CONFIG.apiUrl,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + getAuthToken()
    }
});

export default {
    upload(formData) {
        return httpClient.post('upload/upload-file', formData);
    },

    uploadFile(formData) {
        return httpClient.post('upload/upload', formData);
    },
}